import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Delivery from '../images/freeDelivery.png'
import Figure from 'react-bootstrap/Figure'
import FigureCaption from 'react-bootstrap/FigureCaption'
import { ImFacebook2 } from "react-icons/im";
import { SiInstagram } from "react-icons/si";
import { SiNextdoor } from "react-icons/si";
import NextdoorLogo_Lime from '../images/NextdoorLogo_Lime.png'
import facebook from "../images/facebook.png"
import Instagram from "../images/Instagram.png"
import './footer.css'


export default function Footer(){
    return(
        
        <div id="footer">
           <Container fluid>
           <Row>
            <Col>
           {/* <Figure>
                    <Figure.Image 
                    src={Delivery}
                    width={125}
                    id="footer-image"
                    />
                    <Figure.Caption id="caption">
                        <a href="https://www.holisticgreenthings.com/delivery">Find out how to get free deliver</a>
                    </Figure.Caption>    
    </Figure>*/}
    <Image src={Delivery} width={125} id="footer-image"/>
            </Col>
            <Col id="contact-footer">
            <h5>Contact Us</h5>
                <p className="contact-info">Call us at<a href="tel: 520-486-1753"> 520-486-1753</a></p>
                <p className="contact-info">Email us at <a href="mailto: support@holisticgreenthings.com">support@holisticgreenthings.com</a></p>
            </Col>
            <Col id="connect">
            <h5>Connect with us</h5>
                <a href="https://facebook.com/HolisticGreenThings" target="_blank" rel="noreferrer noopener"><Image className="connect-info" src={facebook} width={50}/></a>
                <a href="https://www.instagram.com/holisticgreenthings/" target="_blank" rel="noreferrer noopener"><Image className="connect-info" src={Instagram} width={50}/></a>
                <a href="https://nextdoor.com/pages/holistic-green-things-tucson-az" target="_blank" rel="noreferrer noopener" ><Image className="connect-info" src={NextdoorLogo_Lime} width={100}/></a>
            </Col>
            <Col id="insurance">
            <img src="https://app.fliprogram.com/media/site_seals/0001/68/c288e32614e886c589ab46aaa1216322b21273f2.png" alt="FLIP Insurance Seal" width="150px" height="150px"  />
            </Col>
            </Row>
            </Container>
            </div>
    
         
        
    )
}