import logo from '../images/logo.png'
import Container from 'react-bootstrap/Nav'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Image from 'react-bootstrap/Image'
import './navigation.css'

export function Navigation() {
    return(
        <header>
            <div id="logo-header">
               {/*} <img id="logo" src={logo} alt="Logo" />*/}
    <div id="navigation">
                <Navbar expand="lg">
               
                       
                    <Container id="navigation">
                   
                   
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                 
                        <Navbar.Collapse id="basic-navbar-nav">
                        <Navbar.Brand>
                        <img id="logonav" src={logo} ></img>
                        
                </Navbar.Brand>
                  
                            <Nav id="navigation">
               
                                

                                <Nav.Link href="/" className="nav-item">Home</Nav.Link>
                                <Nav.Link href="about" className="nav-item">About Us</Nav.Link>
                                <Nav.Link href="recipes" className="nav-item">Recipes</Nav.Link>
                                <Nav.Link href="microgreens" className="nav-item">Microgreens</Nav.Link>
                                <Nav.Link href="contact" className="nav-item">Contact Us</Nav.Link>
                                <Nav.Link href="delivery" className="nav-item">Delivery/Pick-up</Nav.Link>
                                <Nav.Link href="commercial" className="nav-item">Restraunts/Stores</Nav.Link>
                                <Nav.Link href="events" className="nav-item">Events</Nav.Link>
                                {/*<Nav.Link href="cart" className="nav-item"><ShoppingCart size={30}/><Badge>{cartItems.length}</Badge></Nav.Link>*/}
                                {/*<Nav.Link href="cart" className="nav-item">Cart</Nav.Link>*/}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                </div>
            </div>    
        </header>
    )   
}