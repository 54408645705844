import Table from 'react-bootstrap/Table'
import './events.css'

export default function December(){
    return(
        <>
        <h1 className="december-title">December</h1>
        <Table responsive bordered size="sm">
        <thead>
                <tr>
                    <th>Sunday</th>
                    <th>Monday</th>
                    <th>Tuesday</th>
                    <th>Wednesay</th>
                    <th>Thursday</th>
                    <th>Friday</th>
                    <th>Saturday</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <p>1</p>
                    </td>
                    <td>
                        <p>2</p>
                        <p>Home Delivery Day</p>
                    </td>
                    <td>
                        <p>3</p>
                    </td>
                    <td>
                        <p>4</p>
                    </td>
                    <td>
                        <p>6</p>
                    </td>
                    <td>
                        <p>7</p>

                    </td>
                    <td>
                        <p>8</p>
                    </td>
                    
                </tr>
                <tr>
                    <td>
                        <p>9</p>
                    </td>
                    <td>
                        <p>10</p>
                        <p>Home Delivery Day</p>
                    </td>
                    <td>
                        <p>11</p>
                    </td>
                    <td>
                        <p>12</p>
                    </td>
                    <td>    
                        <p>13</p>
                    </td>
                    <td>
                        <p>14</p>
                    </td>
                    <td>
                        <p>15</p>
                    </td>
                    
                </tr>
                <tr>
                    <td>
                        <p>16</p>
                    </td>
                    <td>
                        <p>17</p>
                        <p>Home Delivery Day</p>
                    </td>
                    <td>
                        <p>18</p>
                    </td>
                    <td>
                        <p>19</p>
                    </td>
                    <td>
                        <p>20</p>
                    </td>
                    <td>
                        <p>21</p>
                    </td>
                    <td>
                        <p>22</p>
                        <p>Rustic Roots Farmers Market</p>
                        <span>10 A.M. to 2 P.M.</span>
                        
                    </td>
                    
                </tr>
                <tr>
                    <td>
                        <p>23</p>
                    </td>
                    <td>
                        <p>24</p>
                        <p>Home Delivery Day</p>
                    </td>
                    <td>
                        <p>25</p>
                    </td>
                    <td>
                        <p>26</p>
                    </td>
                    <td>
                        <p>27</p>
                    </td>
                    <td>
                        <p>28</p>
                    </td>
                    <td>
                        <p>29</p>
                    </td>
                    
                </tr>
                <tr>
                <td>
                        <p>30</p>
                    </td>
                    <td>
                        <p>31</p>
                        <p>Home Delivery Day</p>
                    </td>
                    <td>
                        <p></p>
                    </td>
                    <td>
                        <p></p>
                    </td>
                    <td>
                        <p></p>
                    </td>
                    <td>
                        <p></p>
                    </td>
                    <td>
                        <p></p>
                    </td>
                    
                    
                </tr>
                <tr>
                <td>
                        <p></p>
                    </td>
                    <td>
                        <p></p>
                    </td>
                    <td>
                        <p></p>
                    </td>
                    <td>
                        <p></p>
                    </td>
                    <td>
                        <p></p>
                    </td>
                    <td>
                        <p></p>
                    </td>
                    <td>
                        <p></p>
                    </td>
                    
                </tr>
            </tbody>
       
        </Table>
        </>
    )
}