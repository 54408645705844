import "./events.css"
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import January  from './January'
import Febuary from './Febuary'
import  March from './March'
import April from './April'
import May from './May'
import June from './June'
import July from './July'
import August from './August'
import September from './September'
import October from './October'
import November from './November'
import December from './December'


export function Events(){
    return(
        <>
        <h1 className="events-title">Events Page</h1>
        <Tabs
            defaultActiveKey="december"
         
        >
            <Tab eventKey="january" title="January">
                <January />
            </Tab>
            <Tab eventKey="febuary" title="Febuary">
                <Febuary />
            </Tab>
            <Tab eventKey="march" title="March">
                <March />
            </Tab>
            <Tab eventKey="april" title="April">
                <April />
            </Tab>
            <Tab eventKey="may" title="May">
                <May />
            </Tab>
            <Tab eventKey="june" title="June">
                <June />
            </Tab>
            <Tab eventKey="july" title="July">
                <July />
            </Tab>
            <Tab eventKey="august" title="August">
                <August />
            </Tab>
            <Tab eventKey="september" title="September">
                <September />
            </Tab>
            <Tab eventKey="october" title="October">
                <October />
            </Tab>
            <Tab eventKey="november" title="November">
                <November />
            </Tab>
            <Tab eventKey="december" title="December">
                <December />
            </Tab>
        </Tabs>
        </>
    )
}