import './home.css'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
import Sunflower from "../images/Sunflower1Cropped.jpg"
import DinnerRolls from '../images/dinnerRollsrotated.jpg'
import Linguini from '../images/linguine.jpg'
import FrenchBread from '../images/frenchBreadRotated.jpg'
import LionsMane from '../images/lionsmaneonwoodRotated.jpg'
import MarketSampler from '../images/marketSampler2cropped.jpg'
import PinkOyster from '../images/pinkmushroomwithdoily.jpg'
import Leeks from '../images/leeksCropped.jpg'
import Wheatgrass from '../images/wheatgrass-package.jpg'



function Home(){
    return(
        <div>
             <h1 id="home-title">Welcome to Holistic Green Things.</h1>
             <p id="home-description">Where you can find farm fresh food.</p>
            <Container>
           
           <Row id='sunflower'>
            <Col><Image src={Sunflower}  className="imageHome"/></Col>
            <Col className='col align-self-center'>
                <h2 id="microgreens">Micorgreens</h2>
                <span>Grown in our local Urban Farm</span>
                </Col>
           
            </Row>
            <Row id="pasta">
                
                <Col className='col align-self-center'>
                    <h2>Fresh Pasta</h2>
                    <span>Prepared locally fresh in small batches</span>
                </Col>
                <Col>
                    <Image src={Linguini} className="imageHome"></Image>
                </Col>
            </Row>
            <Row id="mushroom">
                <Col>
                    <Image src={LionsMane} className="imageHome2"></Image>
                </Col>
                <Col className="align-self-center">
                    <h2>Gourmet Mushrooms</h2>
                    <span>The gourmet mushrooms are grown in our local urban farm</span>
                </Col>
            </Row>
            <Row id="bread">
               
                <Col className="align-self-center">
                <h2 >Home Made Bread</h2>
                <span>Fresh bread baked in our local urban farm kitchen</span>
                </Col>
                <Col>
                    <Image src={FrenchBread} className="imageHome2"></Image>
                </Col>
            </Row> 
            <Row id='wheatgrass'>
                <Col>
                    <Image src={Wheatgrass} className="imageHome2"></Image>
                </Col>
                <Col className="align-self-center"> 
                    <h2>Wheatgrass</h2>
                    <span>We even have locally grown wheatgrass for your fur baby.</span>
                </Col>
            </Row> 
           </Container>
       
        </div>  
    )
}

export default Home