import './about.css'
import Container from 'react-bootstrap/Container'
import Footer from './Footer'
import Image from 'react-bootstrap/Image'
import Amaranth from '../images/amaranth.jpg'


export default function About() {
    return(
        <div>
            <Image src={Amaranth} id="amaranth"></Image>
            <h1 id="about-header">About Us</h1>
            <Container>
                <p>
                Holistic Green Things was founded because of our 
                devotion to real whole food. Real whole food is not processed, 
                genetically modified, grown with pesticides or grown in non-nutrient 
                earth. With today’s comercial farming and food industry all of 
                these issues are present. Then we asked ourselves what can we do to 
                change this? We believe that small local urban farming is a solution 
                to all of this. We have decided to pride ourselves with small urban farming.
                 We know how important it is to us to eat healthy food and we want to 
                 share this with you. Supporting us is not only promoting a local business, 
                 but local urban farming. We look forward to sharing our local urban 
                 farm goods with you. 
                </p>

            </Container>

          
        </div>
    )
}